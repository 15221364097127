$theme-colors: (
  "primary": #0074d9,
  "danger": #ff4136
);

@import './assets/scss/app.scss';

.srv-validation-message{
    color: $danger-color;
}

.login-card .login-main.login-tab .tab-content{
  height: auto !important;
}

.login-card .login-main.login-tab{
  border-top-left-radius: 10px;
}

.login-card {
//    min-height: 100vh;
//    display: -webkit-box;
//    display: -ms-flexbox;
//    display: flex;
//    -webkit-box-align: center;
//    -ms-flex-align: center;
//    align-items: center;
//    -webkit-box-pack: center;
//    -ms-flex-pack: center;
//    justify-content: center;
//    margin: 0 auto;
//  background: url("./assets/images/login/login_bg.jpg");
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center;
//    padding: 30px 12px
}

.show-hide span:before {
  content: "";
}
.show-hide{
  top: 56px;
}
